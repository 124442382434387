import { createAction, props } from '@ngrx/store';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';
import { FundsOpenSearch } from '@fund-base/types/funds/funds.types';
import {
  FiltersSearch,
  FundsFilter,
  SearchHistory,
  SearchHistoryQuery,
  SearchQuery,
} from '@fund-base/types/funds/funds-search.types';
import { FundingExpertsList, FundingExpertsSearchFilters } from '@fund-base/types/funding-expert/expert.types';

// funds loading
export const setFundsLoading = createAction('[Funds] Set funds loading', props<{ loading: boolean }>());

export const setFundsFilterCountLoading = createAction(
  '[Funds] Set funds loading',
  props<{ filtersCountLoading: boolean }>()
);

// fetch funds by id
export const fetchFundsById = createAction(
  '[Funds] fetch fund By id',
  props<{ id?: string; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// fetch funds
export const fetchFunds = createAction(
  '[Funds] Fetch funds',
  props<{
    filtersSearch: FiltersSearch;
    fundList?: FundsOpenSearch[];
    onError?: ErrorCallback;
    onSuccess?: SuccessCallback;
  }>()
);

// fetch funds visitors
export const fetchFundsVisitors = createAction(
  '[Funds] Fetch funds visitors',
  props<{
    filtersSearch: FiltersSearch;
    fundList?: FundsOpenSearch[];
    onError?: ErrorCallback;
    onSuccess?: SuccessCallback;
  }>()
);

// fetch number funds visitors
export const fetchNumberFundsVisitors = createAction(
  '[Funds] Fetch number funds visitors',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// fetch funds Through History
export const fetchFundsThroughHistory = createAction(
  '[Funds] Fetch funds Through History',
  props<{
    filtersSearch: FiltersSearch;
    searchQuery?: SearchHistoryQuery;
    onError?: ErrorCallback;
    onSuccess?: SuccessCallback;
  }>()
);

// on load more funds
export const fetchFundsAfter = createAction(
  '[Funds] Fetch more Funds by searchQuery',
  props<{
    filtersSearch: FiltersSearch;
    fundList?: FundsOpenSearch[];
    page?: number;
    size?: number;
    onError?: ErrorCallback;
    onSuccess?: SuccessCallback;
  }>()
);

// set funds
export const setFunds = createAction(
  '[Funds] Set Funds',
  props<{
    fundsList?: FundsOpenSearch[];
    fundsCount: number;
    filtersCount: FundsFilter[];
    page?: number;
    size?: number;
    onError?: ErrorCallback;
    onSuccess?: SuccessCallback;
  }>()
);

// export const fetchFundsFilterCount = createAction(
//   '[Funds] Fetch Funds Filters Count',
//   props<{ query: any, onError?: ErrorCallback, onSuccess?: SuccessCallback }>()
// );

export const setFundsFilterCount = createAction(
  '[Funds] Set Funds Filters Count',
  props<{ filtersCount: FundsFilter[] }>()
);

// set user history
export const setUserHistory = createAction(
  '[Funds] Set User History',
  props<{ usersSearchHistory?: SearchHistory[] | undefined; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// set funds search query
export const setFundsSearch = createAction(
  '[Funds] set Funds Search',
  props<{ searchQuery?: SearchQuery; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// set Funds After
export const setFundsAfter = createAction(
  '[Funds] Set Funds After',
  props<{ fundsList?: FundsOpenSearch[]; page?: number; size?: number }>()
);

// set funds filter
export const setAdvancedFilters = createAction(
  '[Funds] Set Funds Filter',
  props<{ filtersSearch: FiltersSearch; onSuccess?: SuccessCallback }>()
);

// fetch funding experts
export const fetchFundingExperts = createAction(
  '[Funds] Fetch funding experts',
  props<{
    filters?: FundingExpertsSearchFilters;
    pagination?: number;
    onError?: ErrorCallback;
    onSuccess?: SuccessCallback;
  }>()
);

// fetch funding experts
export const fetchMoreFundingExperts = createAction(
  '[Funds] Fetch More funding experts',
  props<{
    filters?: FundingExpertsSearchFilters;
    pagination?: number;
    onError?: ErrorCallback;
    onSuccess?: SuccessCallback;
  }>()
);

// set funds
export const setFundingExperts = createAction(
  '[Funds] Set funding experts',
  props<{ fundingExperts?: FundingExpertsList }>()
);

export const setFundsPage = createAction('[Funds] Set Funds page', props<{ page: number }>());

export const setFundsSize = createAction('[Funds] Set Funds size', props<{ size: number }>());

// fetch funding experts
export const sendShareFund = createAction(
  '[Funds] send share fund ',
  props<{ body?: any; languate?: string; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);
