import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs';
import { PlanService } from '@fund-base/services/plan/plan.service';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { ErrorService } from '@fund-base/services/error/error.service';
import { showToast } from '@fund-base/store/actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { planServiceInjectionToken } from '@fund-base/constants/plan/plan.constants';
import { fetchPlans, setPlanLoading, setPlans } from '@fund-base/store/actions/plan.actions';

@Injectable()
export class PlanEffects {
  // fetch plans
  fetchPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPlans),
      switchMap(action => {
        // set plan loading
        this.store.dispatch(setPlanLoading({ loading: true }));

        // call authenticate from service
        return this.planService
          .getPlans() //TODO: check if token is necessary
          .pipe(
            switchMap(response => {
              // on success callback
              if (!!action?.onSuccess) action?.onSuccess?.call(response);
              // on success
              return [setPlans({ plans: response }), setPlanLoading({ loading: false })];
            }),
            catchError(errorRes => {
              // get error
              const error = this.errorService.getErrorMessage(errorRes);

              // on error callback
              if (!!action?.onError) action?.onError?.call(error);

              // on error
              return [setPlanLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private storageService: StorageService,
    private errorService: ErrorService,
    @Inject(planServiceInjectionToken) private planService: PlanService
  ) {}
}
