import { Action, createReducer, on } from '@ngrx/store';
import { AtlasDataState, initialAtlasDataState } from '@fund-base/store/state/atlas-data.state';
import { setAtlasEvents, setAtlasNews } from '@fund-base/store/actions/atlas-data.actions';

export const featureAtlasDataKey = 'atlas-data';

const _atlasDataReducer = createReducer<AtlasDataState, Action>(
  initialAtlasDataState,
  on(setAtlasEvents, (state, { atlasEvents }) => ({ ...state, atlasEvents })),
  on(setAtlasNews, (state, { atlasNews }) => ({ ...state, atlasNews }))
);

export function atlasDataReducer(state: AtlasDataState | undefined, action: Action) {
  return _atlasDataReducer(state, action);
}
