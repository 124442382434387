import { Action, createReducer, on } from '@ngrx/store';
import {
  fetchUsersFavouritesFundsList,
  setUserLoading,
  setUsersFavouritesFundsList,
} from '@fund-base/store/actions/users.action';
import { initialUserStateState } from '@fund-base/store/state/users.state';

export const featureUserKey = 'users';

const _userReducer = createReducer(
  initialUserStateState,
  on(fetchUsersFavouritesFundsList, state => state),
  on(setUserLoading, (state, { loading }) => ({ ...state, loading })),
  on(setUsersFavouritesFundsList, (state, { userFavouritesList }) => ({ ...state, userFavouritesList }))
);

export function userReducer(state: any, action: Action) {
  return _userReducer(state, action);
}
