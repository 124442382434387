import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Plan } from '@fund-base/types/plan/plan.types';
import { Observable } from 'rxjs';
import { planBaseApiUrl } from '@fund-base/constants/plan/plan.constants';

@Injectable({ providedIn: 'root' })
export class PlanService {
  constructor(private httpClient: HttpClient) {}

  getPlans(): Observable<Plan[]> {
    return this.httpClient.get<Plan[]>(`${planBaseApiUrl}`);
  }
}
