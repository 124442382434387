import { createAction, props } from '@ngrx/store';
import { Notification } from '@fund-base/types/notifications/notifications.types';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';

export const setNotifications = createAction(
  '[Notifications] Set Notifications',
  props<{ notifications?: Notification[] }>()
);
export const setNotificationsRead = createAction(
  '[Notifications] Set Notifications Read',
  props<{
    notificationId: number;
    notification?: Notification[];
    onError?: ErrorCallback;
    onSuccess?: SuccessCallback;
  }>()
);
