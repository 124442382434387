import { Action, createReducer, on } from '@ngrx/store';
import {
  fetchWorkPlans,
  setWorkPlan,
  setWorkPlanLoading,
  setWorkPlans,
} from '@fund-base/store/actions/workplan.action';
import { initialWorkPlansState, WorkPlanState } from '@fund-base/store/state/workplan.state';

export const featureWorkPlanKey = 'workplan';

const _workPlanReducer = createReducer<WorkPlanState, Action>(
  initialWorkPlansState,
  on(fetchWorkPlans, state => state),
  on(setWorkPlanLoading, (state, { loading }) => ({ ...state, loading })),
  on(setWorkPlan, (state, { workplan }) => ({ ...state, workplan })),
  on(setWorkPlans, (state, { workplans }) => ({ ...state, workplans }))
);

export function workplanReducer(state: WorkPlanState | undefined, action: Action) {
  return _workPlanReducer(state, action);
}
