import { Component, OnInit } from '@angular/core';
import {
  defaultDirection,
  defaultLanguage,
  directionLocalStorageKey,
  languageLocalStorageKey,
} from '@fund-base/constants/locale/locale.constants';
import { PosthogService } from '@fund-base/services/posthog.service';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { setDirection, setLanguage } from '@fund-base/store/actions/locale.actions';
import { setScreenSize } from '@fund-base/store/actions/ui.actions';
import { selectIsGlobalLoading, selectScreenSize } from '@fund-base/store/selectors/ui.selectors';
import { ScreenSize } from '@fund-base/types/ui/ui.types';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { Destroyable } from './shared/components/destroyable/destroyable';
import { selectUser } from './store/selectors/auth.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends Destroyable implements OnInit {
  // global loading
  globalLoading$: Observable<boolean> = this.store.select(selectIsGlobalLoading);
  isVisitorUser: boolean;
  user$ = this.store.select(selectUser);

  constructor(private store: Store, private storageService: StorageService, private posthog_service: PosthogService) {
    super();

    // language
    this.initLanguage();

    // direction
    this.initDirection();
  }

  ngOnInit(): void {
    this.user$.subscribe((user: any) => {
      if (user) {
        const userProfile = {
          Name: user.organization.owner_name,
          Email: user.email,
          Organization: user.organization.organization_name,
          Status: user.organization ? 'User' : 'Visitor',
          Plan: user.organization ? this.getPlan(user.organization.membership[0].plan) : 'Free',
        };
        this.posthog_service.identifyUser(user.id, userProfile);
      }
    });
  }

  initLanguage() {
    this.store.dispatch(
      setLanguage({
        language: this.storageService.getItem(languageLocalStorageKey) ?? defaultLanguage,
      })
    );
  }

  initDirection() {
    this.store.dispatch(
      setDirection({
        direction: this.storageService.getItem(directionLocalStorageKey) ?? defaultDirection,
      })
    );
  }

  getPlan(plan: any): string {
    if (plan.id === 7 || plan.id === 11) {
      return 'Essential';
    } else if (plan.id === 8) {
      return 'Enterprise';
    }
    return 'Professional';
  }

  onScreenSizedChanged(screenSize: ScreenSize) {
    this.store
      .select(selectScreenSize)
      .pipe(take(1))
      .subscribe(currentScreenSize => {
        if (currentScreenSize !== screenSize) {
          this.store.dispatch(
            setScreenSize({
              screenSize,
            })
          );
        }
      });
  }
}
