import { HttpParams } from '@angular/common/http';
import { isNotNull } from '@fund-base/utils/helpers/general/general.helpers';

export const generateParams = (queryParams: any): HttpParams => {
  let params: HttpParams = new HttpParams();

  Object.entries(queryParams?.filter).forEach(([key, value]) => {
    if (isNotNull(value)) {
      params = params?.set(key, String(value));
    }
  });

  // paging
  if (isNotNull(queryParams?.paging?.page) && isNotNull(queryParams?.paging?.size)) {
    params = params?.set('page', String(queryParams?.paging?.page));
    params = params?.set('size', String(queryParams?.paging?.size));
  }

  // sort
  if (isNotNull(queryParams?.sort?.type)) {
    params = params?.set('sort', String(queryParams?.sort?.type));
  }

  return params;
};
