import { createAction, props } from '@ngrx/store';
import { FundType, Interest } from '@fund-base/types/types/types.types';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';

// loading
export const setTypesLoading = createAction('[Types] Set types loading', props<{ loading: boolean }>());

// fund types
export const fetchFundTypes = createAction(
  '[Types] Fetch fund types',
  props<{ onSuccess?: SuccessCallback; onError?: ErrorCallback }>()
);

export const setFundTypes = createAction('[Types] Set fund types', props<{ fundTypes: FundType[] }>());

// interest
export const fetchInterests = createAction(
  '[Types] Fetch interests',
  props<{ onSuccess?: SuccessCallback; onError?: ErrorCallback }>()
);

export const setInterests = createAction('[Types] Set interests', props<{ interests: Interest[] }>());
