import { Action, createReducer, on } from '@ngrx/store';
import { initialOrganizationState, OrganizationState } from '@fund-base/store/state/organization.state';
import {
  fetchOrganizationCommentsList,
  fetchOrganizationSettings,
  fetchUsersList,
  setOrganization,
  setOrganizationCommentsList,
  setOrganizationLoading,
  setOrganizationSettings,
  setUsersList,
  updateOrganizationOnboardingSkipped,
  updateOrganizationSettings,
} from '../actions/organization.actions';

export const featureOrganizationKey = 'organization';

const _organizationReducer = createReducer<OrganizationState, Action>(
  initialOrganizationState,
  on(fetchOrganizationSettings, updateOrganizationSettings, state => state),
  on(setOrganization, (state, { organization }) => ({ ...state, organization })),
  on(setOrganizationSettings, (state, { organizationSettings }) => ({ ...state, organizationSettings })),
  on(updateOrganizationOnboardingSkipped, (state, { completed }) => ({ ...state, onboardingSkipped: completed })),
  on(setOrganizationLoading, (state, { loading }) => ({ ...state, onboardingLoading: loading })),
  on(fetchUsersList, state => state),
  on(setUsersList, (state, { organizationUsersList }) => ({ ...state, organizationUsersList })),
  on(fetchOrganizationCommentsList, state => state),
  on(setOrganizationCommentsList, (state, { organizationComments }) => ({ ...state, organizationComments }))
);

export function organizationReducer(state: OrganizationState | undefined, action: Action) {
  return _organizationReducer(state, action);
}
