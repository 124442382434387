import { Action, createReducer, on } from '@ngrx/store';
import {
  fetchFundingExperts,
  fetchFunds,
  setAdvancedFilters,
  setFundingExperts,
  setFunds,
  setFundsFilterCount,
  setFundsFilterCountLoading,
  setFundsLoading,
  setFundsPage,
  setFundsSearch,
  setFundsSize,
  setUserHistory,
} from '@fund-base/store/actions/funds.actions';
import { FundsState, initialFundsState } from '@fund-base/store/state/funds.state';
import { setIsRead } from '../actions/fund.actions';

export const featureFundsKey = 'funds';

const _fundsReducer = createReducer<FundsState, Action>(
  initialFundsState,
  on(fetchFunds, fetchFundingExperts, state => state),
  on(setFundsLoading, (state, { loading }) => ({ ...state, loading })),
  on(setFundsFilterCountLoading, (state, { filtersCountLoading }) => ({ ...state, filtersCountLoading })),
  on(setFunds, (state, { fundsList, fundsCount, filtersCount, page, size }) => ({
    ...state,
    fundsList,
    fundsCount,
    filtersCount,
    page,
    size,
  })),
  on(setFundsFilterCount, (state, { filtersCount }) => ({ ...state, filtersCount })),
  on(setAdvancedFilters, (state, { filtersSearch }) => ({ ...state, lastFilter: filtersSearch, filtersSearch })),
  on(setUserHistory, (state, { usersSearchHistory }) => ({ ...state, usersSearchHistory })),
  on(setFundsSearch, (state, { searchQuery }) => ({ ...state, searchQuery })),
  on(setFundingExperts, (state, { fundingExperts }) => ({ ...state, fundingExperts })),
  on(setFundsPage, (state, { page }) => ({ ...state, page })),
  on(setFundsSize, (state, { size }) => ({ ...state, size })),
  on(setIsRead, (state, { fundId }) => ({
    ...state,
    fundsList: state.fundsList?.map(fund => {
      if (!fund?.fund?.fb_id) {
        return fund;
      }
      return +fund.fund?.fb_id === +fundId
        ? {
            ...fund,
            fund: {
              ...fund.fund,
              readAt: new Date(),
            },
          }
        : fund;
    }),
  }))
);

export function fundsReducer(state: FundsState | undefined, action: Action) {
  return _fundsReducer(state, action);
}
