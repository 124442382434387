import { SmartAgent } from '@fund-base/types/smart-agent/smart-agent.types';

export interface SmartAgentState {
  smartAgents?: SmartAgent[];
  smartAgent?: SmartAgent;
  loading: boolean;
}

export const initialSmartAgentState: SmartAgentState = {
  loading: false,
  smartAgents: [],
  smartAgent: undefined,
};
