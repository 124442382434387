import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { TypesService } from '@fund-base/services/types/types.service';
import { setInterests } from '@fund-base/store/actions/types.action';
import { selectLanguage } from '@fund-base/store/selectors/locale.selectors';
import { Interest } from '@fund-base/types/types/types.types';

@Injectable({
  providedIn: 'root',
})
export class InterestListResolver implements Resolve<Interest[]> {
  language$ = this.store.select(selectLanguage);

  constructor(private typesService: TypesService, private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Interest[]> {
    try {
      return this.language$.pipe(
        take(1),
        switchMap(lang => {
          return this.typesService?.getInterests(lang).pipe(
            switchMap(response => {
              if (response) {
                this.store.dispatch(
                  setInterests({
                    interests: response.interests,
                  })
                );
              }
              return of(response?.interests ?? []);
            })
          );
        })
      );
    } catch (e) {
      return EMPTY;
    }
  }
}
