import { User } from '@fund-base/types/auth/auth.types';
import { v4 as uuidv4 } from 'uuid';

// fixed mock data
export const mockUser: User = generateMockUser();

export const mockUsers: User[] = generateMockUsers(20);

export const mockAuthToken: string = generateMockAuthToken();

// mock generators
export function generateMockUser(): User {
  return {
    id: uuidv4(),
    first_name: 'Roi',
    last_name: 'Peretz',
    // username: 'roi-aptenobytes',
    email: 'roi@aptenobytes.com',
    phone: 'sss',
    password: 'sss',
    terms: true,
  };
}

export function generateMockUsers(amount: number): User[] {
  return Array(amount).map(i => generateMockUser());
}

export function generateMockAuthToken(): string {
  return uuidv4();
}
