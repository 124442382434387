import { Direction, Language } from '@fund-base/types/locale/locale.types';

// language
export const defaultLanguage = Language.English;
export const languageLocalStorageKey = 'language';
export const ReferrerStorageKey = 'ref';

// direction
export const defaultDirection = Direction.LTR;
export const directionLocalStorageKey = 'direction';
