import { baseApiUrl } from '@fund-base/constants/general/general.constants';

export const userBaseApiUrl = `${baseApiUrl}/users`;
export const updateProfileSettingsApiUrl = `${userBaseApiUrl}/update-profile-settings`;
export const userResetPasswordApiUrl = `${userBaseApiUrl}/reset-password`;
export const userCalendarEventsApiUrl = `${userBaseApiUrl}/calendar-events`;
export const updateUsersRolesApiUrl = `${userBaseApiUrl}/users-permission`;
export const getUsersFavouritesFundsListApiUrl = `${userBaseApiUrl}/favourite-funds`;
export const updateUsersFavouritesFundsListApiUrl = `${userBaseApiUrl}/favourite-funds`;
export const addNewUserApiUrl = `${userBaseApiUrl}/add-user-to-organization`;

export const getOrganizationCommentsListApiUrl = `${userBaseApiUrl}/comments-funds`;
export const addOrRemoveOrganizationCommentsListApiUrl = `${userBaseApiUrl}/comments`;
