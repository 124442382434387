import { createAction, props } from '@ngrx/store';
import { AuthResetPasswordDto, ForgotPasswordDto, User, Visitor } from '@fund-base/types/auth/auth.types';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';
import { Organization } from '@fund-base/types/organization/organization.types';

// set auth loading
export const setAuthLoading = createAction('[Auth] Set auth loading', props<{ loading: boolean }>());

// set user
export const setUser = createAction('[Auth] Set user', props<{ user?: User }>());

export const setVisitor = createAction('[Auth] Set visitor', props<{ visitor?: Visitor }>());

// set token
export const setToken = createAction('[Auth] Set token', props<{ token?: string }>());

// authenticate
export const authenticate = createAction(
  '[Auth] Authenticate',
  props<{ token?: string; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// login
export const login = createAction(
  '[Auth] Login',
  props<{ email?: string; password?: string; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// signup details
export const signUpOldFbAccount = createAction(
  '[Auth] Sign Up Old FB Account',
  props<{
    email: string;
    userName: string;
    oldPassword: string;
    newPassword: string;
    onError?: ErrorCallback;
    onSuccess?: SuccessCallback;
  }>()
);

// signup details
export const signUp = createAction(
  '[Auth] Sign Up Details',
  props<{ user?: User; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const signUpVisitor = createAction(
  '[Auth] Sign Up Visitor Details',
  props<{ user?: Visitor; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const signUpVisitorPlan = createAction(
  '[Auth] Sign Up Visitor Plan',
  props<{ plan?: any; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const signUpVisitorUpdate = createAction(
  '[Auth] Sign Up Visitor Update Details',
  props<{ user?: any; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const transformVisitorToUser = createAction(
  '[Auth] Sign Up Visitor To User',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// signup organization
export const signUpOrganization = createAction(
  '[Auth] Sign Up Organization',
  props<{ organization?: Organization; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// signup email-verification
export const signUpEmailVerification = createAction(
  '[Auth] Sign Up email-verification',
  props<{ verified?: boolean; token?: string; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// signup is-email-verified
export const signUpIsEmailVerified = createAction(
  '[Auth] Sign Up is email verified',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

export const signUpIsVisitorEmailVerified = createAction(
  '[Auth] Sign Up is visitor email verified',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// signup re-send-email-verification
export const signUpReSendEmailVerification = createAction(
  '[Auth] Sign Up re-send-email-verification',
  props<{ token?: string; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// signup membership
export const signUpMembership = createAction(
  '[Auth] Sign Up Membership',
  props<{ plan_id?: number; token?: string; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// signup success
export const signUpSuccess = createAction(
  '[Auth] Sign Up Success',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// sign out
export const signOut = createAction(
  '[Auth] Sign Out',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// get google auth info
export const getGoogleAuthInfo = createAction(
  '[Auth] get google auth info',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// auth with google
export const authWithGoogle = createAction(
  '[Auth] auth google',
  props<{ email: string; accessToken: string; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// login with google
export const loginWithGoogle = createAction(
  '[Auth] login by google',
  props<{ email: string; accessToken: string; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// get google auth info Calendar
export const getGoogleAuthInfoCalendar = createAction(
  '[Auth] get google auth info calendar ',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// auth with google Calendar
export const authWithGoogleCalendar = createAction(
  '[Auth] auth google calendar ',
  props<{ email: string; accessToken: string; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// get forgot password email
export const forgotPassword = createAction(
  '[Auth] forgot password request',
  props<{ forgotPasswordDto: ForgotPasswordDto; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// change password request
export const authResetPassword = createAction(
  '[Auth] reset password request',
  props<{ resetPasswordDto: AuthResetPasswordDto; onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// fetch number ai letters visitors
export const fetchNumbersFreePlanVisitors = createAction(
  '[Auth] Fetch number ai letters visitors',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);

// update number ai letters visitors
export const updateNumberAiLettersVisitors = createAction(
  '[Auth] Update number ai letters visitors',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);
