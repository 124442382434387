import { AtlasEvents } from '@fund-base/types/atlas-events/atlas-events.types';
import { AtlasNews } from '@fund-base/types/atlas-news/atlas-news.types';

export interface AtlasDataState {
  atlasEvents?: AtlasEvents[];
  atlasNews?: AtlasNews[];
}

export const initialAtlasDataState: AtlasDataState = {
  atlasEvents: [],
  atlasNews: [],
};
