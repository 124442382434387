import { createAction, props } from '@ngrx/store';
import { Plan } from '@fund-base/types/plan/plan.types';
import { ErrorCallback, SuccessCallback } from '@fund-base/types/general/functions';

// set plan
export const setPlan = createAction('[Plan] Set plan', props<{ plan?: Plan }>());

// plan loading
export const setPlanLoading = createAction('[Plan] Set plan loading', props<{ loading: boolean }>());

//get plans
export const fetchPlans = createAction(
  '[plan] Fetch plans',
  props<{ onError?: ErrorCallback; onSuccess?: SuccessCallback }>()
);
// set plans
export const setPlans = createAction('[Plan] Set plans', props<{ plans?: Plan[] }>());
