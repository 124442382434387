import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { adminFundLanguagesApiUrl } from '@fund-base/constants/admin/admin.constants';
import { Language } from '@fund-base/types/locale/locale.types';
import { FundLanguages } from '@fund-base/types/languages/languages.types';

@Injectable({ providedIn: 'root' })
export class LanguagesService {
  constructor(private httpClient: HttpClient) {}

  // get funds languages
  getFundLanguages(lang?: Language): Observable<{ fundLanguages: FundLanguages[] }> {
    return this.httpClient.get<{ fundLanguages: FundLanguages[] }>(`${adminFundLanguagesApiUrl}/${lang}`);
  }
}
