import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ErrorService } from '@fund-base/services/error/error.service';
import {
  createSubmission,
  createTask,
  createWorkPlan,
  deleteTask,
  fetchWorkPlans,
  setWorkPlanLoading,
  setWorkPlans,
  updateSubmission,
  updateTask,
  updateWorkPlan,
} from '@fund-base/store/actions/workplan.action';
import { catchError, switchMap } from 'rxjs';
import { UserService } from '@fund-base/services/user/user.service';
import { showToast } from '../actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { WorkPlanService } from '@fund-base/services/workplan/workplan.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class WorkPlanEffects {
  createWorkplan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createWorkPlan),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setWorkPlanLoading({ loading: true }));

        // call  from service
        return this.workplanService.createWorkplan(action?.workplan).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);
            // on success
            return [
              setWorkPlanLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('workplan created successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setWorkPlanLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  updateWorkplan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWorkPlan),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setWorkPlanLoading({ loading: true }));

        // call  from service
        return this.workplanService.updateWorkplan(action?.workplan).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);
            // on success
            return [
              setWorkPlanLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('workplan updated successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setWorkPlanLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  updateSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSubmission),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setWorkPlanLoading({ loading: true }));

        // call  from service
        return this.workplanService.updateSubmission(action?.updateSubmission).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setWorkPlanLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Submission updated successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setWorkPlanLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  createSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSubmission),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setWorkPlanLoading({ loading: true }));

        // call  from service
        return this.workplanService.createSubmission(action?.submission).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setWorkPlanLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Submission created successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setWorkPlanLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  createTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createTask),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setWorkPlanLoading({ loading: true }));

        // call  from service
        return this.workplanService.createTask(action?.task).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);
            // on success
            return [
              setWorkPlanLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Task created successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setWorkPlanLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  updateTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTask),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setWorkPlanLoading({ loading: true }));

        // call  from service
        return this.workplanService.updateTask(action?.task).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);
            // on success
            return [
              setWorkPlanLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Task updated successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setWorkPlanLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  deleteTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTask),
      switchMap(action => {
        // set  loading
        this.store.dispatch(setWorkPlanLoading({ loading: true }));

        // call  from service
        return this.workplanService.deleteTask(action?.taskId).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setWorkPlanLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Task deleted successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setWorkPlanLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  fetchWorkplans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchWorkPlans),
      switchMap(action => {
        this.store.dispatch(setWorkPlanLoading({ loading: true }));

        return this.workplanService.getWorkplansByQuery(action.params).pipe(
          switchMap(response => {
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            return [setWorkPlanLoading({ loading: false }), setWorkPlans({ workplans: response })];
          }),
          catchError(errorRes => {
            const error = this.errorService.getErrorMessage(errorRes);

            if (!!action?.onError) action?.onError?.call(error);

            return [setWorkPlanLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // filterWorkplans$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(filterWorkPlans),
  //     withLatestFrom(this.store.select(selectUser)),
  //     switchMap(([action]) => {
  //         this.store.dispatch(setWorkPlanLoading({loading: true}));
  //
  //         return this.workplanService.searchWorkplans(action.textQuery)
  //           .pipe(
  //             switchMap(response => {
  //               if (!!action?.onSuccess) action?.onSuccess?.call(response);
  //               return [
  //                 setWorkPlanLoading({loading: false}),
  //                 setWorkPlans({workplans: response})
  //               ]
  //             }),
  //             catchError(errorRes => {
  //               const error = this.errorService.getErrorMessage(errorRes);
  //
  //               if (!!action?.onError) action?.onError?.call(error);
  //
  //               return [
  //                 setWorkPlanLoading({loading: false}),
  //                 showToast({content: error, status: ToastType.failure})
  //               ];
  //             })
  //           );
  //       }
  //     )
  //   )
  // );

  constructor(
    private actions$: Actions,
    private store: Store,
    private errorService: ErrorService,
    private userService: UserService,
    private translocoService: TranslocoService,
    private workplanService: WorkPlanService
  ) {}
}
