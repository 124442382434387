import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentIntent } from '@stripe/stripe-js';
import {
  expertBaseApiUrl,
  invoicesBaseApiUrl,
  membershipBaseApiUrl,
  subscriptionBaseApiUrl,
} from '@fund-base/constants/payment/payment.contants';
import { Observable } from 'rxjs';
import { Invoice } from '@fund-base/types/payment/payment.types';
import { CreateMembershipDto, Membership } from '@fund-base/types/membership/membership.types';

@Injectable({ providedIn: 'root' })
export class PaymentsService {
  constructor(private httpClient: HttpClient) {}

  createPaymentSubscription(
    planId: number,
    isYearly: boolean
  ): Observable<{ subscriptionId: string; client_secret: string }> {
    return this.httpClient.post<{ subscriptionId: string; client_secret: string }>(subscriptionBaseApiUrl, {
      planId,
      isYearly,
    });
  }

  createMembership(membership: CreateMembershipDto): Observable<Membership> {
    return this.httpClient.post<Membership>(membershipBaseApiUrl, membership);
  }

  createExpertPaymentIntent(expertId?: number, numberOfHours?: string, currency?: string): Observable<PaymentIntent> {
    return this.httpClient.post<PaymentIntent>(expertBaseApiUrl, { expertId, numberOfHours, currency });
  }

  cancelSubscription(): Observable<any> {
    return this.httpClient.delete<any>(subscriptionBaseApiUrl);
  }

  getAllInvoices(): Observable<{ invoicesList: Invoice[] }> {
    return this.httpClient.get<{ invoicesList: Invoice[] }>(invoicesBaseApiUrl);
  }
}
