import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@fund-base/shared/guards/admin-guard/admin.guard';
import { OnboardingGuard } from '@fund-base/shared/guards/onboarding/onboarding.guard';
import { InterestListResolver } from '@fund-base/shared/resolvers/admin/admin-interests.resolver';
import { VisitorsGuard } from '@fund-base/shared/guards/visitors/visitors.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'auth' },
  {
    path: 'auth',
    // canActivate: [AuthGuard],
    loadChildren: () => import('@fund-base/auth/auth.module').then(module => module.AuthModule),
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    loadChildren: () => import('@fund-base/admin/admin.module').then(module => module.AdminModule),
  },
  {
    path: 'onboarding',
    canActivate: [OnboardingGuard],
    resolve: { interests: InterestListResolver },
    loadChildren: () => import('@fund-base/onboarding/onboarding.module').then(m => m.OnboardingModule),
  },
  {
    path: 'visitors',
    canActivate: [VisitorsGuard],
    loadChildren: () => import('@fund-base/visitors/visitors.module').then(m => m.VisitorsModule),
  },
  {
    path: 'terms-of-use',
    canActivate: [VisitorsGuard],
    loadChildren: () =>
      import('@fund-base/terms-of-use/terms-of-use-routing.module').then(m => m.TermsOfUseRoutingModule),
  },
  {
    path: 'privacy-policy',
    canActivate: [VisitorsGuard],
    loadChildren: () =>
      import('@fund-base/privacy-policy/privacy-policy-routing.module').then(m => m.PrivacyPolicyRoutingModule),
  },

  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () =>
      import('@fund-base/pagenotfound/page-not-found-routing.module').then(m => m.PageNotFoundRoutingModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
