export class Environment {
  mock = false;
  production = false;
  webUrl = 'https://dev-fundbase.atlas.org.il';
  apiUrl = 'https://dev-fundbase.atlas.org.il/api';
  baseGoogleUrl = '807145161260-70lbv5u47i7modifpguvq1h7dj40j0dq.apps.googleusercontent.com';

  stripePublishableKey =
    'pk_test_51MJvskCP7woOjl6H4eZKjCyWfDTQOoRQxC4Oe370qAHCXgNSGV0Qmb2aFUxRwqHhdoisSxcZRz1jxV9Xc63FPxee00MX6lIt9f';
  OpenAiKey = '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';
  posthog = 'phc_uWOzhE5Z5GXZ1DUjkqs3RxoijY8YlZ7FWNGFPQdGyuz';
}

export const environment = new Environment();
