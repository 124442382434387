import { Action, createReducer, on } from '@ngrx/store';
import { initialNotificationsState, NotificationsState } from '@fund-base/store/state/notifications.state';
import { setNotifications } from '@fund-base/store/actions/notifications.actions';

export const featureNotificationsKey = 'notifications';

const _notificationsReducer = createReducer<NotificationsState, Action>(
  initialNotificationsState,
  on(setNotifications, (state, { notifications }) => ({ ...state, notifications }))
);

export function notificationsReducer(state: NotificationsState | undefined, action: Action) {
  return _notificationsReducer(state, action);
}
