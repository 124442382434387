import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs';
import { showToast } from '@fund-base/store/actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { Store } from '@ngrx/store';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { ErrorService } from '@fund-base/services/error/error.service';
import { TranslocoService } from '@ngneat/transloco';
import {
  fetchFundTypes,
  fetchInterests,
  setFundTypes,
  setInterests,
  setTypesLoading,
} from '@fund-base/store/actions/types.action';
import { TypesService } from '@fund-base/services/types/types.service';
import { selectLanguage } from '@fund-base/store/selectors/locale.selectors';

@Injectable()
export class TypesEffects {
  // fetch fund types
  fetchFundTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFundTypes),
      withLatestFrom(this.store.select(selectLanguage)),
      switchMap(([action, languageFromStore]) => {
        // set fund types loading
        this.store.dispatch(setTypesLoading({ loading: true }));

        // call authenticate from service
        return this.typesService.getFundTypes(languageFromStore).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [setFundTypes({ fundTypes: response.fundTypes ?? [] }), setTypesLoading({ loading: false })];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setTypesLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // fetch interests
  fetchInterests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchInterests),
      withLatestFrom(this.store.select(selectLanguage)),
      switchMap(([action, languageFromStore]) => {
        // set interests loading
        this.store.dispatch(setTypesLoading({ loading: true }));

        // call authenticate from service
        return this.typesService.getInterests(languageFromStore).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [setInterests({ interests: response.interests ?? [] }), setTypesLoading({ loading: false })];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setTypesLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private storageService: StorageService,
    private errorService: ErrorService,
    private translocoService: TranslocoService,
    private typesService: TypesService
  ) {}
}
