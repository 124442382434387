import { NgModule } from '@angular/core';
import { AppStoreModule } from '@fund-base/store/store.module';
import { AppComponent } from '@fund-base/app/app.component';
import { AppRoutingModule } from '@fund-base/app/app-routing.module';
import { AppSharedModule } from '@fund-base/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BackButtonDirective } from '@fund-base/app/back-button.directive';
import { NavigationService } from '@fund-base/app/navigation.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from '@fund-base/shared/interceptors/token.interceptor';
import { OAuthModule } from 'angular-oauth2-oidc';
import { LanguageInterceptor } from '@fund-base/shared/interceptors/language.interceptor';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@fund-base/environments/environment';
import { HtmlService } from '@fund-base/app/htmlService.service';
import { MatDialogModule } from '@angular/material/dialog';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    AppComponent,
    BackButtonDirective  
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    AppRoutingModule,
    AppSharedModule,
    AppStoreModule,
    HttpClientModule,
    CKEditorModule,
    MatTableModule,
    MatCheckboxModule,
    NgxStripeModule.forRoot(environment?.stripePublishableKey),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [''],
        sendAccessToken: true,
      },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    NavigationService,
    HtmlService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
  ],
})
export class AppModule {}
