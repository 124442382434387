import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { UserState } from '@fund-base/store/state/users.state';
import { featureUserKey } from '@fund-base/store/reducers/users.reducer';
import { UserFavourites } from '@fund-base/types/users/user-favourites.types';

const getUserLoading = (state: UserState): boolean | undefined => state.loading;
const getUsersFavouritesFunds = (state: UserState): UserFavourites[] | undefined => state.userFavouritesList;

// settings state
export const selectUserState: MemoizedSelector<object, UserState> = createFeatureSelector<UserState>(featureUserKey);

export const selectUserLoading: MemoizedSelector<object, boolean | undefined> = createSelector(
  selectUserState,
  getUserLoading
);

export const selectUsersFavouritesFunds: MemoizedSelector<object, UserFavourites[] | undefined> = createSelector(
  selectUserState,
  getUsersFavouritesFunds
);
