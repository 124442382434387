import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResetPasswordDto, User } from '@fund-base/types/auth/auth.types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  addNewUserApiUrl,
  addOrRemoveOrganizationCommentsListApiUrl,
  getOrganizationCommentsListApiUrl,
  getUsersFavouritesFundsListApiUrl,
  updateProfileSettingsApiUrl,
  updateUsersFavouritesFundsListApiUrl,
  updateUsersRolesApiUrl,
  userResetPasswordApiUrl,
} from '@fund-base/constants/user/user.constants';
import { CalendarEvent } from '@fund-base/types/calendar/calendar.types';
import { mockCalendarEvents } from '@fund-base/services/user/user.mock.data';
import { ProfileSettings } from '@fund-base/types/settings/settings.types';
import { UserRolePair } from '@fund-base/types/organization/organization.types';
import { UserFavourites } from '@fund-base/types/users/user-favourites.types';
import { OrganizationUsersCommentsFunds } from '@fund-base/types/users/organization-users-comments.types';
import { AddNewUserTypes } from '@fund-base/types/users/add-new-user.types';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private httpClient: HttpClient) {}

  private isUserIsraelSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setIsUserIsrael(isIsrael: boolean): void {
    this.isUserIsraelSubject.next(isIsrael);
  }

  getIsUserIsrael(): Observable<boolean> {
    return this.isUserIsraelSubject.asObservable();
  }

  // update user profile
  updateProfileSettings(
    userUpdateState?: ProfileSettings,
    profileFile?: File | null
  ): Observable<{ user?: User; accessToken?: string }> {
    const formData = new FormData();
    if (!!profileFile) {
      formData.append('file', profileFile);
    }
    const userDtoToString = JSON.stringify(userUpdateState);
    formData.append('updateUserDto', userDtoToString);
    return this.httpClient.put<{}>(updateProfileSettingsApiUrl, formData);
  }

  generateHttpParamsFromDto(dto: any) {
    let params = new HttpParams();
    Object.keys(dto)?.forEach(key => {
      params = params.set(key, dto[key]);
    });
    return params;
  }

  // reset password
  resetPassword(resetPasswordDto: ResetPasswordDto): Observable<{ user?: User; accessToken?: string }> {
    return this.httpClient.post<{ user?: User; accessToken?: string }>(userResetPasswordApiUrl, resetPasswordDto);
  }

  // update Users Permission
  updateUsersRoles(usersRolesList?: UserRolePair[]): Observable<{ usersList?: User[] }> {
    return this.httpClient.put<{ usersList?: User[] }>(updateUsersRolesApiUrl, usersRolesList);
  }

  getUserCalendarEvents(userId?: string, startDate?: Date, endDate?: Date): Observable<CalendarEvent[]> {
    // return this.httpClient.post<CalendarEvent[]>(
    //     userCalendarEventsApiUrl,
    //     {
    //         userId,
    //         startDate,
    //         endDate
    //     },
    // );
    return of(mockCalendarEvents);
  }

  getUsersFavouritesFundsList(): Observable<{ userFavouritesList?: UserFavourites[] }> {
    return this.httpClient.get<{ userFavouritesList?: UserFavourites[] }>(getUsersFavouritesFundsListApiUrl);
  }

  updateUsersFavouritesFundsList(fundId?: number): Observable<{ userFavouritesList: UserFavourites[] }> {
    return this.httpClient.put<{ userFavouritesList: UserFavourites[] }>(
      `${updateUsersFavouritesFundsListApiUrl}/${fundId}`,
      {}
    );
  }

  getOrganizationCommentsList(
    fundId?: number
  ): Observable<{ organizationComments?: OrganizationUsersCommentsFunds[] }> {
    return this.httpClient.get<{ organizationComments?: OrganizationUsersCommentsFunds[] }>(
      `${getOrganizationCommentsListApiUrl}/${fundId}`
    );
  }

  removeACommentFromList(commentId?: number): Observable<{ organizationComments?: OrganizationUsersCommentsFunds[] }> {
    return this.httpClient.delete<{ organizationComments?: OrganizationUsersCommentsFunds[] }>(
      `${addOrRemoveOrganizationCommentsListApiUrl}/${commentId}`,
      {}
    );
  }

  addACommentToList(
    fundId?: number,
    comment?: string
  ): Observable<{ organizationComments?: OrganizationUsersCommentsFunds[] }> {
    return this.httpClient.put<{ organizationComments?: OrganizationUsersCommentsFunds[] }>(
      `${addOrRemoveOrganizationCommentsListApiUrl}/${fundId}`,
      { comment }
    );
  }

  addNewUser(language: string, newUserDetails: AddNewUserTypes): Observable<{ data: User[] }> {
    return this.httpClient.post<{ data: User[] }>(addNewUserApiUrl, {
      newUser: {
        newUserDetails: newUserDetails,
        language: language,
      },
    });
  }
}
