import { Action, createReducer, on } from '@ngrx/store';
import {
  fetchFundTypes,
  fetchInterests,
  setFundTypes,
  setInterests,
  setTypesLoading,
} from '@fund-base/store/actions/types.action';
import { initialTypesState, TypesState } from '@fund-base/store/state/types.state';

export const featureTypesKey = 'types';

const _typesReducer = createReducer<TypesState, Action>(
  initialTypesState,
  on(fetchFundTypes, fetchInterests, state => state),
  on(setTypesLoading, (state, { loading }) => ({ ...state, loading })),
  on(setFundTypes, (state, { fundTypes }) => ({ ...state, fundTypes })),
  on(setInterests, (state, { interests }) => ({ ...state, interests }))
);

export function typesReducer(state: TypesState | undefined, action: Action) {
  return _typesReducer(state, action);
}
