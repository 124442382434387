import { FundLanguages } from '@fund-base/types/languages/languages.types';

export interface LanguagesState {
  loading: boolean;
  fundLanguages: FundLanguages[];
}

export const initialLanguagesState: LanguagesState = {
  loading: false,
  fundLanguages: [],
};
