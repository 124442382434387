import { Invoice } from '@fund-base/types/payment/payment.types';

export interface PaymentsState {
  invoices?: Invoice[];
  loading: boolean;
}

export const initialPaymentsState: PaymentsState = {
  invoices: [],
  loading: false,
};
