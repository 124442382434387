export enum CalendarEventType {
  task,
  workplan,
  atlasEvent,
}

export interface CalendarEvent {
  id: number;
  type?: CalendarEventType;
  title?: string;
  fromDate: Date;
  toDate?: Date | null;
}

// helpers

export function calendarEventTypeToBackgroundColor(calendarEventType?: CalendarEventType) {
  switch (calendarEventType) {
    case CalendarEventType.task:
      return '#D4ECFF';
    case CalendarEventType.workplan:
      return '#0A287C33';
    case CalendarEventType.atlasEvent:
      return '#FEAE2E33';
    default:
      return '#F6F6F7';
  }
}

export function calendarEventTypeToTextColor(calendarEventType?: CalendarEventType) {
  switch (calendarEventType) {
    case CalendarEventType.task:
      return '#1890FC';
    case CalendarEventType.workplan:
      return '#001D5F';
    case CalendarEventType.atlasEvent:
      return '#FEB62E';
    default:
      return '#9A9A9A';
  }
}

export function calendarEventTypeToText(calendarEventType?: CalendarEventType) {
  switch (calendarEventType) {
    case CalendarEventType.task:
      return 'Task';
    case CalendarEventType.workplan:
      return 'Workplan';
    case CalendarEventType.atlasEvent:
      return 'Atlas Event';
    default:
      return 'Unknown';
  }
}
