import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AuthState } from '@fund-base/store/state/auth.state';
import { User, Visitor } from '@fund-base/types/auth/auth.types';
import { featureAuthKey } from '@fund-base/store/reducers/auth.reducer';

// store
const getLoading = (state: AuthState): boolean => state.loading;
const getUser = (state: AuthState): User | undefined => state.user;
const getVisitor = (state: AuthState): Visitor | undefined => state.visitor;
const getToken = (state: AuthState): string | undefined => state.token;

// settings state
export const selectAuthState: MemoizedSelector<object, AuthState> = createFeatureSelector<AuthState>(featureAuthKey);

export const selectAuthLoading: MemoizedSelector<object, boolean> = createSelector(selectAuthState, getLoading);

export const selectUser: MemoizedSelector<object, User | undefined> = createSelector(selectAuthState, getUser);

export const selectVisitor: MemoizedSelector<object, Visitor | undefined> = createSelector(selectAuthState, getVisitor);

export const selectToken: MemoizedSelector<object, string | undefined> = createSelector(selectAuthState, getToken);
